<template>
  <div class="d-page-wrapper">
    <div class="d-page-toolbar">
      <d-search-input
        v-model="search"
        @submit="onSearchSubmit"
      ></d-search-input>
    </div>

    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      :page="pageNumber"
      :items-per-page="pageSize"
      :server-items-length="totalCount"
      disable-sort
      hide-default-footer
      class="d-data-table d-data-table--rows-clickable"
      @page-count="pageCount = $event"
    ></v-data-table>

    <v-pagination
      v-model="pageNumber"
      :length="pageCount"
      :total-visible="15"
      class="my-5"
      @input="onPageInput"
    ></v-pagination>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import pageMixin from '@/mixins/page.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'

import DSearchInput from '@/components/DSearchInput'


import { servicesPageSize } from '../../constants'

export default {
  components: {
    DSearchInput
  },


  mixins: [
    pageMixin,
    snackbarMixin
  ],


  data () {
    return {
      search: null,
      pageCount: 0,
      pageNumber: 1,
      pageSize: servicesPageSize
    }
  },


  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('service', ['servicesForUser', 'totalCount']),

    tableHeaders () {
      const headers = [
        { text: this.$t('service.table.vehicleLicenseNumber'), value: 'vehicleLicenseNumber', cellClass: 'text-no-wrap' },
        { text: this.$t('service.table.date'), value: 'date', cellClass: 'text-no-wrap' },
        { text: this.$t('service.table.type'), value: 'serviceTypeName' },
        { text: this.$t('service.table.mileage'), value: 'mileage' },
        { text: this.$t('service.table.glassProtection'), value: 'glassProtection' },
        { text: this.$t('service.table.comment'), value: 'comment', cellClass: 'd-overflow-ellipsis' }
      ]

      return headers
    },

    tableItems () {
      return this.servicesForUser.map(item => {
        return {
          ...item
        }
      })
    }
  },


  methods: {
    ...mapActions('service', ['loadServicesByChainIdForUser']),

    onSearchSubmit () {
      this.reloadTableItems(1)
    },

    onPageInput () {
      this.reloadTableItems()
    },

    async reloadTableItems (page) {
      try {
        this.startPageLoading()

        if (page && page !== this.pageNumber) {
          this.pageNumber = page
        }

        const items = this.loadServicesByChainIdForUser({
          chainId: this.currentUser.chainId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          search: this.search
        })

        return items
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      } finally {
        this.stopPageLoading()
      }
    }
  },


  async created () {
    try {
      this.setPageTitle(this.$t('nav.services'))
      this.startPageLoading()
      await this.reloadTableItems(1)
    } catch (error) {
      this.showSnackbarError()
      this.$log.error(error)
    } finally {
      this.stopPageLoading()
    }
  }
}
</script>
